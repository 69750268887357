<template>
  <b-container>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              Listado de recepciones de ordenes de compras
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              @click="$bvModal.show('bv-modal-example')"
              class="mr-2"
            >
              Nueva recepción
            </b-button>
          </template>
          <template v-slot:body>
            <b-table hover :items="rows" :fields="columns">
              <template v-slot:cell(item)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template v-slot:cell(status)="data">
                <b-badge pill variant="success">{{ data.item.status }}</b-badge>
              </template>
            </b-table>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <div>
      <b-modal
        ref="ubication"
        size="lg"
        scrollable
        id="bv-modal-example"
        hide-footer
      >
        <template #modal-title> Órdenes de compra facturadas</template>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'ListPurchaseReception',
  async created () {
    this.$store.commit("SET_BREADCRUMB", this.items);
  },
  mounted () {
    core.index()
  },
  methods: {},
  data () {
    return {
      columns: [
        { label: 'CÓDIGO', key: 'codigo', class: 'text-center' },
        { label: 'ÁREA', key: 'area', class: 'text-center' },
        { label: 'ESTADO', key: 'status', class: 'text-center' },
        { label: 'OPCIONES', key: 'options', class: 'text-center' }
      ],
      rows: [
        {
          id: 1,
          codigo: '#23423',
          area: 'Vedruna',
          status: 'Revisado',
          editable: false
        }
      ],
      breadcrumb: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Movimiento de stock de productos',
          active: true
        }
      ]
    }
  }
}
</script>
